<template>
  <div class="get_m_a_s">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="queryData"
          class="demo-form-inline"
          size="mini"
          ref="queryData"
        >
          <el-form-item label="卡号" prop="id">
            <el-input
              v-model="queryData.did"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="department_id">
            <el-select v-model="queryData.did" filterable>
              <el-option label="启用" value="1"></el-option>
              <el-option label="停用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围" prop="date">
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getTable">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
            <el-button @click="shwoCard()">发卡</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          label="使用人姓名"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column label="卡号" prop="sum_sex0" align="center">
        </el-table-column>
        <el-table-column label="量表数量" prop="sum_sex1" align="center">
        </el-table-column>
        <el-table-column label="状态" prop="sort" align="center">
          <template>
            <div>启用</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template>
            <el-button size="mini">查看</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码  -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="0"
      ></el-pagination>
    </div>
    <!-- 发卡弹窗 -->
    <div class="CardBox">
      <el-dialog
        title="发卡管理"
        :close-on-click-modal="false"
        :visible.sync="CardBox"
        width="700px"
        center
      >
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="卡号:">
            <el-input
              v-model="formInline.user"
              placeholder=""
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用人姓名:">
            <el-input
              v-model="formInline.user"
              placeholder=""
              size="mini"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <div class="content content2">
          <el-table :data="scaleList" style="width: 100%" height="450px">
            <el-table-column
              label="量表名称"
              prop="measure_title"
            ></el-table-column>
            <el-table-column
              :label="`是否启用（已启用：${checkNum}）`"
              prop="sort"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.check"
                  :true-label="1"
                  :false-label="0"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="最大测评次数" prop="sort" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.num"
                  size="mini"
                  placeholder=""
                  maxlength="2"
                  :disabled="!scope.row.check"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="CardBox = false">取 消</el-button>
          <el-button type="primary" @click="addCard()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { loadArchivesInfo } from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import { get_measure_user_statistics } from "@/api/user_info_statistical.js";
import axios from "axios";
export default {
  data() {
    return {
      scaleData: [],
      scaleList: [],
      tableData: [],
      date: [],
      queryData: {
        did: "",
        min: "",
        stime: "",
        etime: "",
      },
      CardBox: false,
      page: 0,
      pageSize: 15,
      formInline: {},
    };
  },
  created() {
    // 获取量表列表
    this.loadScaleData();
    // 获取所有科室
    this.getTable();
  },
  methods: {
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        res.data.data.forEach((v) => {
          v.status_measure.forEach((v) => {
            this.$set(v, "check", 0);
            this.$set(v, "num", null);
            this.scaleList.push(v);
          });
        });
      } else {
        return;
      }
    },
    //获取表格数据
    getTable() {
      console.log(this.date);
      let stime = "";
      let etime = "";
      if (this.date) {
        stime = this.date[0] / 1000;
        etime = this.date[1] / 1000;
      }
      // 时间处理
      let data = {
        did: this.queryData.did || null,
        mid: this.queryData.mid || null,
        stime: stime || null,
        etime: etime || null,
      };
      get_measure_user_statistics(data).then((res) => {
        console.log(res);
        this.tableData = res.data;
      });
    },
    // 重置
    resetForm(formName) {
      this.queryData = {
        did: "",
        mid: "",
      };
      this.data = [];
    },
    //显示发药弹窗
    shwoCard() {
      this.CardBox = true;
    },
    // 最大页码
    handleSizeChange() {},
    // 页码监听
    handleCurrentChange() {},
    // 新增发卡
    addCard() {
      let newArr = [];
      this.scaleList.forEach((v) => {
        if (v.check == 1) {
          newArr.push(v);
        }
      });
      console.log(newArr);
    },
  },
  computed: {
    checkNum() {
      let newArr = [];
      this.scaleList.forEach((v) => {
        if (v.check == 1) {
          newArr.push(v);
        }
      });
      return newArr.length;
    },
  },
};
</script>
<style lang="less">
.get_m_a_s {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 745px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .content2 {
    height: unset;
    min-height: unset;
    margin-top: unset;
  }
  .el-table__footer-wrapper {
    .el-table__footer {
      .has-gutter {
        tr {
          td {
            background: rgb(252, 218, 28);
            color: #3c3c3c;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
  // 页码
  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
  }
  //
}
</style>